import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import axios from 'axios'
import { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function OperationAdding({ open, handleClose, children }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,

    };

    const [operationName, setoperationName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [operationChoices, setOperationChoices] = useState([]);
    const [price, setPrice] = useState("");

    // Update state handlers
    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(operationName)
        switch (name) {
            case 'operationName':
                setoperationName(value);
                break;
            case 'quantity':
                setQuantity(value);
                break;
            case 'price':
                setPrice(value);
                break;
            default:
                break;
        }
    };

    const addNewProduct = () => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        let data = { operation: operationName, count: quantity, price: price }
        axios.post(baseUrl + `/daily/operations/`, data, { headers: headers })
            .then(function (response) {
                NotificationManager.success("Успешно додаден зафат", "", 2000)
                handleClose();
                window.location.reload();
            })
            .catch(function (response) {
                NotificationManager.error("Настана грешкa", "", 2000)
            })

    }

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        axios.get(baseUrl + '/daily/get-daily-operation-choices/', { headers: headers })
            .then(function (response) {
                setOperationChoices(response.data)
            })

    }, [])
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h2>Додади активност</h2>



                <FormControl autoComplete="off" sx={{ width: 400, paddingBottom: 2 }} className='doctor-select'>
                    <InputLabel id="doctor-select-label"  >Операција</InputLabel>
                    <Select
                        labelId="doctor-select-label"
                        id="demo-simple-select"
                        value={operationName}
                        name='operationName'
                        label=""
                        onChange={handleChange}
                    >
                        {operationChoices.map((ch, index) => (
                            <MenuItem key={index} value={ch.operation}>
                                {ch.operation}
                            </MenuItem>
                        ))}
                    </Select>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Количина"
                        name="quantity"
                        value={quantity}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        type='number'
                        margin="normal"
                        label="Цена"
                        name="price"
                        value={price}
                        onChange={handleChange}
                    />
                </FormControl>


                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="contained" onClick={handleClose}>Откажи</Button>
                    <Button variant="contained" color="success" onClick={() => addNewProduct()} sx={{ ml: 2 }}>Додади</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default OperationAdding;
